import {
  SET_ALL_NFT,
  SET_NFT_SEARCH,
  SET_NFT_OFFSET,
  SET_NFT_FILTER,
} from "../action/actionConstants";

const initialState = {
  nftData: null,
  nftSearch: '',
  offset: 0,
  filter: {},
};
const AllNftReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ALL_NFT:
      return {
        ...state,
        nftData: state.offset === 0 ? action.payload : state.nftData.concat(action.payload)
      };
    case SET_NFT_SEARCH:
      return {
        ...state,
        nftSearch: action.payload,
        offset: 0,
      }
    case SET_NFT_OFFSET:
      return {
        ...state,
        offset: action.payload
      }
    case SET_NFT_FILTER:
      return {
        ...state,
        filter: action.payload,
        offset: 0,
      }
    default:
      return state;
  }
};

export default AllNftReducer;
