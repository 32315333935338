const dev = {
  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:9002"
      : "https://api.amadz.com",
  // BASE_URL: "http://beta-art-scape.codesfortomorrow.tech",
  LIVE_URL: "https://bullpen.cardpooler.com/",
  INFURA_KEY: "https://rinkeby.infura.io/v3/e7dff36398c0441f91f7cce00b4109e0",
};

export const contracts = {
  POLYGON: {
    WyvernProxyRegistry: "0xb576B7FF9aD2F5beD6f76aFFE3B93178bc14EB5a",
    WyvernExchange: "0x21A2ee37A4DB036D0ae99891f17d01386172FC4f",
    WyvernTokenTransferProxy: "0xf7Fca1Be27Aff988A7D2b6A9e71289FD2af3913C",
    AssetContractShared: "0xd1C649ffC6fa9CbaC480089BAa9FC7f5D8feDAF8",
    MarketV2: "0x4823E0d1CB26a768756A326a3806Bcdd9762Efe1",
    Lootbox: "0xaA7d9B436aBcB464A3976AC51482c57879c00f4E",
  },
};

export const feeRecipient = "0xd2645b8E1DE56d53236bd0EB2728Efa9Ae710003";
export const zeroAddress = "0x0000000000000000000000000000000000000000";
export const noAddress = null;

/**
 * Deployed exchange contract on network
 */
export const chainId = {
  ETHEREUM: "0x3",
  POLYGON: "0x13881",
};

export const tokenAddress = {
  ETHEREUM: {
    ETH: zeroAddress,
    WETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
  },
  POLYGON: {
    MATIC: zeroAddress,
    WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
  },
};

const ENV = dev;

export default ENV;
