import {
  SET_SELECTED_NFT_ID,
  SET_SELECTED_RENT_NFT_ID,
  SET_SELECTED_NFT_DATA,
  SET_SELECTED_RENT_NFT_DATA,
  RESET_NFT_DATA,
  RESET_RENT_NFT_DATA,
  SET_SELECTED_ASSEMBLE_NFT

} from "../action/actionConstants";

const initialState = {
  nftId: null,
  nftData: null,
  selectedAssembleNft: [],
  selectedTeam:''
};

const NftReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_NFT_ID: case SET_SELECTED_RENT_NFT_ID:
      return {
        ...state,
        nftId: action.payload
      };

    case SET_SELECTED_ASSEMBLE_NFT:
      return {
        ...state,
        selectedAssembleNft: action.payload.selectedNFT,
        selectedTeam:action.payload.selectedTeam
      };

    case SET_SELECTED_NFT_DATA: case SET_SELECTED_RENT_NFT_DATA:
      return {
        ...state,
        nftData: action.payload
      };
    case RESET_NFT_DATA: case RESET_RENT_NFT_DATA:
      return {
        nftId: null,
        nftData: null,
      };
    default:
      return state;
  }
};

export default NftReducer;
