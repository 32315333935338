
import { SET_SELECTED_PROCESS_IMAGE, } from "../action/actionConstants";

const initialState = {
    selectedProcessImage: null,
};

const SelectedImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PROCESS_IMAGE:
            return {
                selectedProcessImage: action.payload
            };

        default:
            return state;
    }
};

export default SelectedImageReducer;
