import { combineReducers } from 'redux';
import ToastReducer from "./reducers/ToastReducer";
import UserDetails from './reducers/UserDetails';
import WalletReducer from './reducers/WalletReducer';
import NftReducer from './reducers/NftReducer';
import CreateSellOrder from './reducers/CreateSellOrder'
import SelectedImageReducer from './reducers/selectedImageReducer'
import AllNftReducer from './reducers/AllNftReducer';

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  userDetails: UserDetails,
  walletReducer: WalletReducer,
  nftReducer: NftReducer,
  createSellOrder: CreateSellOrder,
  selectedImageReducer: SelectedImageReducer,
  allNftReducer: AllNftReducer,
});

export default rootReducer;

