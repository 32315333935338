import {
    SET_USER_DETAILS,
    SET_USER_COLLECTION,
    SET_USER_TOKEN,
    SET_USER_COLLECTION_OFFSET
  } from "../action/actionConstants";
  
  const initialState = {
    userToken:null,
    user: null,
    userCollection: null,  
    offset:0
  };
  
  const UserDetails = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_USER_DETAILS:        
        return {
          ...state,
          user: action.payload
        };
      case SET_USER_COLLECTION:
        return {
          ...state,
          userCollection: state.offset === 0 ? action.payload : state.userCollection.concat(action.payload)
        };
      case SET_USER_TOKEN:
        return {
          ...state,
          userToken: action.payload
        };
      case SET_USER_COLLECTION_OFFSET:
        return {
          ...state,
          offset:action.payload
        }
        
      default:
        return state;
    }
  };
  
  export default UserDetails;
  