import { postReq } from "@utils/ApiHandler";
import React, { useState } from "react";
const ComingSoon = () => {
  const [showMsg, setShowMsg] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    postReq(`/subscription`, { email })
      .then(() => {
        setShowMsg(true);
        setEmail("");
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <section className="bg-theme-darkpurple font-sora pt-5 pb-24">
        <div className="container">
          <div className="pb-5 max-w-[900px] mx-auto">
            <img
              className="h-[50px] "
              src="/images/comingsoon/logo-white.png"
              alt=""
            />
            <p className="text-white ml-12 mt-[-10px] font-semibold">
              Amazing Ads
            </p>
          </div>
          <div className="z-50  pb-2 max-w-[900px] mx-auto">
            <div className="py-5">
              <h4 className="text-white pb-3 text-gradient text-center">
                Advertising at the Speed of Social !
              </h4>
              <h6 className="text-white  pb-3  leading-9 font-semibold font-lar">
                When was the last time a new category in marketing came about?
              </h6>
              <h6 className="text-white  pb-3  leading-9 font-semibold">
                Was it -
              </h6>
              <h6 className="text-white pb-3  leading-9 text-center">
                Digital marketing?
              </h6>
              <h6 className="text-white pb-3  leading-9 text-center">
                Social media marketing?
              </h6>
              <h6 className="text-white pb-3  leading-9 text-center">
                Influencer marketing?
              </h6>

              <h6 className="text-white font-medium  pb-3 leading-7 italic">
                Did it ensure eyeballs ? Was it fine tuned to the audience’s
                interests? Did it pay the entire ecosystem fairly?
              </h6>
              <h5 className=" italic  pb-5 font-medium    leading-9 text-green-400">
                More importantly, did it make life easier for the
                brands/advertisers?
              </h5>
              <h6 className="text-white font-medium   pb-2 leading-7 mt-8">
                Amadz brings ‘Profile Pic Advertising’ to make advertising more
                impactful.
              </h6>
            </div>
          </div>
          <div className="flex gap-14 flex-wrap justify-center  tablet:px-16 px-5  py-12 ">
            <div className="w-32 flex-shrink-0 h-32 rounded-full object-cover border-4 border-white ">
              <img
                className="w-full h-full rounded-full object-cover "
                alt=""
                src="/images/comingsoon/manny-fan.png"
              />
            </div>
            <div className="w-32 flex-shrink-0 h-32 rounded-full object-cover border-4 border-white ">
              <img
                className="w-full h-full rounded-full object-cover "
                alt=""
                src="/images/comingsoon/joseph-card.png"
              />
            </div>
            <div className="w-32 flex-shrink-0 h-32 rounded-full object-cover border-4 border-white ">
              <img
                className="w-full h-full rounded-full object-cover "
                alt=""
                src="/images/comingsoon/manny2.png"
              />
            </div>
            <div className="w-32 flex-shrink-0 h-32 rounded-full object-cover border-4 border-white ">
              <img
                className="w-full h-full rounded-full object-cover "
                alt=""
                src="/images/comingsoon/manny3.png"
              />
            </div>
          </div>
          <div className="z-50  pb-10  max-w-[900px] mx-auto">
            <h6 className="text-white font-semibold  pb-2 leading-7">
              Amadz delivers personalized Ads for the audience on:
            </h6>
            <h6 className="text-white font-semibold  pb-2 leading-7">
              Social Media, Gaming, In the Metaverse and everywhere an Avatar or
              Profile Pic is used.
            </h6>
            <h6 className="text-white font-semibold  pb-2 leading-7">Amadz also delivers personalized Ads on your cell phone through the Amadz Phone App. Please <a className='text-blue-600' href={'/phoneapp'} alt="" >click here</a> to learn more.</h6>
          </div>
          <div className="container">
            <div className="max-w-xl mx-auto rounded-full p-6 relative">
              <p className="text-white text-center font-semibold text-[22px]">
                Sign up for our private beta
              </p>
              {showMsg ? (
                <form className=" bottom-[-50px] w-full lg:w-[70%]  ax-center bg-white !rounded-full h-[55px] ">
                  <p className="font-semibold p-4 pl-10">
                    Thank you! You've been added to the waitlist.
                  </p>
                </form>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  className=" bottom-[-50px] w-full lg:w-[70%] ax-center "
                >
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Enter your email here "
                    className="!rounded-full  bg-white text-black h-[55px] placeholder:text-black/70"
                  />
                  <button
                    type="submit"
                    className="btn z-[10] font-medium gradient_btn text-white rounded-full ay-center right-1 px-10"
                  >
                    submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
