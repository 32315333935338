import * as types from './actionConstants';

export const setTopCategory = (payload) => ({
  type: types.SET_TOP_CATEGORY,
  payload
});

export const setTopCreators = (payload) => ({
  type: types.SET_TOP_CREATORS,
  payload
});

export const setTopTrending = (payload) => ({
  type: types.SET_TOP_TRENDING,
  payload
});

export const setTopBlog = (payload) => ({
  type: types.SET_TOP_BLOG,
  payload
});

export const getTopCategory = () => ({
  type: types.GET_TOP_CATEGORY
});

export const getTopCreators = () => ({
  type: types.GET_TOP_CREATORS

});
export const getTopTrending = () => ({
  type: types.GET_TOP_TRENDING
});

export const getTopBlog = () => ({
  type: types.GET_TOP_BLOG
});

/**
 * Notification Popup
 */
export const showToast = (payload) => ({
  type: types.SHOW_TOAST,
  payload
})

/**wallet redux */
export const setMetamastWalletInfo = (payload) => ({
  type: types.SET_METAMAST_WALLET_INFO,
  payload
});

export const setCoinbaseWalletInfo = (payload) => ({
  type: types.SET_COINBASE_WALLET_INFO,
  payload
});

/**wallet redux */
export const setWalletId = (payload) => ({
  type: types.SET_WALLET_ID,
  payload
});

export const openWalletModal = () => ({
  type: types.OPEN_WALLET_MODAL,
});
export const closeWalletModal = () => ({
  type: types.CLOSE_WALLET_MODAL,
});
/** USER DETAILS*/

export const setUserToken = (payload) => ({
  type: types.SET_USER_TOKEN,
  payload
});

export const getUserDetails = (payload) => ({
  type: types.GET_USER_DETAILS,
  payload
});
export const setUserDetails = (payload) => ({
  type: types.SET_USER_DETAILS,
  payload
});







export const setSelectedProcessImage = (payload) => ({
  type: types.SET_SELECTED_PROCESS_IMAGE,
  payload
});

/* Notification */
export const setNotif = (payload) => ({
  type: types.SET_NOTIF,
  payload
});


/* UI */
export const setUI = (payload) => ({
  type: types.SET_UI,
  payload
});


/* Loader */




export const setSelectedCollectionId = (payload) => ({
  type: types.SET_SELECTED_COLLECTION_ID,
  payload
});

export const getUserCollection = () => ({
  type: types.GET_USER_COLLECTION
});

/**NFT action */
export const setSelectedNftId = (payload) => ({
  type: types.SET_SELECTED_NFT_ID,
  payload
});
export const setSelectedRentNftId = (payload) => ({
  type: types.SET_SELECTED_RENT_NFT_ID,
  payload
});





/** CREATOR DETAILS */
export const setCreatorDetails = (payload) => ({
  type: types.SET_CREATOR_DETAILS,
  payload
});

/** ALL COLLECTIONS */
export const setAllCollections = (payload) => ({
  type: types.SET_ALL_COLLECTIONS,
  payload
});

/** Create sell order */
export const dispatchSellOrder = (payload) => ({
  type: types.DISPATCH_SELL_ORDER,
  payload
});

export const resetSellOrder = (payload) => ({
  type: types.RESET_SELL_ORDER,
  payload
});


export const getExploreCreator = () => ({
  type: types.GET_EXPLORE_CREATOR,
});

export const resetArtistData = () => ({
  type: types.RESET_ARTIST_DATA,
})

/** SET COLLECTION FILTER */
export const setCollectionFilter = (payload) => ({
  type: types.SET_COLLECTION_FILTER,
  payload
});

export const resetCollectionData = () => ({
  type: types.RESET_COLLECTION_DATA,
});
/** SET COLLECTION SEARCH */
export const setCollectionSearch = (payload) => ({
  type: types.SET_COLLECTION_SEARCH,
  payload
});

/** SET COLLECTION CATEGORY */
export const setCollectionCategory = (payload) => ({
  type: types.SET_COLLECTION_CATEGORY,
  payload
});
export const setCollectionOffset = (payload) => ({
  type: types.SET_COLLECTION_OFFSET,
  payload
});
export const setCreatorCollectionOffset = (payload) => ({
  type: types.SET_CREATOR_COLLECTION_OFFSET,
  payload
})
export const setArtistOffset = (payload) => ({
  type: types.SET_ARTIST_OFFSET,
  payload
});
export const setArtistSearch = (payload) => ({
  type: types.SET_ARTIST_SEARCH,
  payload
});

export const setNftOffset = (payload) => ({
  type: types.SET_NFT_OFFSET,
  payload
});
export const getAllNft = () => ({
  type: types.GET_ALL_NFT
});

export const setNftSearch = (payload) => ({
  type: types.SET_NFT_SEARCH,
  payload
});
export const setNftFilter = (payload) => ({
  type: types.SET_NFT_FILTER,
  payload
});
export const setCollectionNftFilter = (payload) => ({
  type: types.SET_COLLECTION_NFT_FILTER,
  payload
});
export const setCollectionNftOffset = (payload) => ({
  type: types.SET_COLLECTION_NFT_OFFSET,
  payload
});
export const setCollectionNftSort = (payload) => ({
  type: types.SET_COLLECTION_NFT_SORT,
  payload
})

export const setCollectionNftSearch = (payload) => ({
  type: types.SET_COLLECTION_NFT_SEARCH,
  payload
})


export const setIsLoading = (payload) => ({
  type: types.SET_IS_LOADING,
  payload
});

export const resetNftData = () => ({
  type: types.RESET_NFT_DATA
})

export const setCollectionChain = (payload) => ({
  type: types.SET_COLLECTION_CHAIN,
  payload
});

export const setCollectionTimeRange = (payload) => ({
  type: types.SET_COLLECTION_TIMERANGE,
  payload
});

export const resetCollectionNft = () => ({
  type: types.RESET_COLLECTION_NFT
})
export const setCollectionSort = (payload) => ({
  type: types.SET_COLLECTION_SORT,
  payload
});
export const setUserCollectionOffset = (payload) => ({
  type: types.SET_USER_COLLECTION_OFFSET,
  payload
})

export const resetCollectionOffset = () => ({
  type: types.RESET_COLLECTION_OFFSET,
})

export const setExchangeRate = (payload) => ({
  type: types.SET_EXCHANGE_RATE,
  payload
})


export const setSelectedAssembleNft = (payload) => ({
  type: types.SET_SELECTED_ASSEMBLE_NFT,
  payload
})
